<template>
  <div class="c-main" ref="main">
    <div class="banner">
      <img src="~@/assets/image/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="qrcode">
        <ul>
          <li>
            <span class="rec">推荐</span>
            <div class="cont">
              <img :src="this.data.length && this.data[0].image_url" alt="" />
              <p class="name">
                ManBetX <br />
                {{ (this.data.length && this.data[0].name) || "万博全站APP" }}
              </p>
            </div>
            <div class="btn" @click="downloadApp(0)">点击下载</div>
          </li>
          <li>
            <span class="rec">推荐</span>
            <div class="cont">
              <img :src="this.data.length && this.data[1].image_url" alt="" />
              <p class="name">
                ManBetX <br />

                {{ (this.data.length && this.data[1].name) || "新万博体育APP" }}
              </p>
            </div>
            <div class="btn" @click="downloadApp(1)">点击下载</div>
          </li>
        </ul>
      </div>
      <div class="c-list">
        <ul>
          <li @click="jumpCustomer(1)">
            <img src="~@/assets/image/line.png" alt="" class="ico" />
            <div class="cont">
              <div class="tit">在线客服1</div>
              <p>Online Customer Service 1</p>
            </div>
          </li>
          <li @click="jumpCustomer(2)">
            <img src="~@/assets/image/line.png" alt="" class="ico" />
            <div class="cont">
              <div class="tit">在线客服2</div>
              <p>Online Customer Service 2</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer-desc">
        <img src="~@/assets/image/text.png" class="img" alt="" />
        <div class="img-list">
          <img src="~@/assets/image/mx.png" alt="" />
          <img src="~@/assets/image/pure.png" alt="" />
          <img src="~@/assets/image/sport.png" alt="" />
          <img src="~@/assets/image/new-site.png" alt="" />
        </div>
        <div class="download" @click="open">前往下载</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
const apiUrl = 'https://api.nxgibghl.com:2053'
export default {
  name: 'Home',
  data () {
    return {
      downloadUrl: {
        1: 'http://m.ne2c5f.com',
        2: 'http://m.ne2c5f.com'
      },
      customerUrl: {
        1: 'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2',
        2: 'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
      },
      ios: false,
      data: [],
      openUrl: ''
    }
  },
  created () {
    this.ios = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
    this.getAppUrl()
  },
  mounted () {
    this.grant()
  },
  methods: {
    grant () {
      var height =
        document.body.clientHeight || document.documentElement.clientHeight
      if (height < 775) {
        this.$refs.main.style.height = height + 'px'
        this.$refs.main.style.overflow = 'auto'
      }
    },

    async getAppUrl () {
      this.data = []
      axios
        .post(apiUrl + '/api/v1/app_download_url')
        .then((res) => {
          this.data = res.data.data || []
        })
        .catch(() => {
          const res = {
            status: 200,
            data: [
              {
                app_type_id: 6,
                name: '万博全站APP最新版',
                image_url: 'https://file1.q5rxr.com/new-site-logo.png',
                for_ios: 1,
                for_android: 1,
                android_app_url:
                  'https://mbxs3f.s3.ap-southeast-1.amazonaws.com/2021/06/d92f1d836bb01ecf1138c5a6fbc5391f.apk',
                on_status: 1,
                ios_app_url: 'https://apple.mrb6ns.cn/?str=tf8bde7gn5'
              },
              {
                app_type_id: 2,
                name: '新万博体育APP',
                image_url: 'https://file3.tkiqw.com/app3.0/logo/logonew.png',
                for_ios: 1,
                for_android: 1,
                android_app_url:
                  'https://mbxs3f.s3.ap-southeast-1.amazonaws.com/%E6%96%B0MX%E4%BD%93%E8%82%B2_2.0.6.10.apk',
                on_status: 1,
                ios_app_url: 'https://apple.mrb6ns.cn/?str=ojocrnmcph'
              },
              {
                app_type_id: 4,
                name: 'iOS纯净版',
                image_url: 'https://file1.q5rxr.com/pure-logo.png',
                for_ios: 1,
                for_android: 0,
                android_app_url: '',
                on_status: 1,
                ios_app_url: 'https://cvtoa.vidrjif.com'
              },
              {
                app_type_id: 3,
                name: 'MX Sports',
                image_url: 'https://file2.q5rxr.com/mx-sports.png',
                for_ios: 1,
                for_android: 1,
                android_app_url:
                  'https://mxs3f.s3.ap-northeast-2.amazonaws.com/MX%20Sports%20%E5%AE%89%E5%8D%934.3.2%EF%BC%881%EF%BC%89%E7%BA%BF%E4%B8%8A%E5%8C%85.apk',
                on_status: 1,
                ios_app_url: 'https://apple.mrb6ns.cn/?str=qsrg9f94hc'
              },
              {
                app_type_id: 1,
                name: '万博全站APP',
                image_url: 'https://file1.q5rxr.com/global-logo.png',
                for_ios: 1,
                for_android: 1,
                android_app_url:
                  'https://mbxs3f.s3.ap-southeast-1.amazonaws.com/app-host001-release_21612_jiagu_sign.apk',
                on_status: 1,
                ios_app_url: 'https://apple.mrb6ns.cn/?str=hp36vm963g'
              },
              {
                app_type_id: 5,
                name: '万博体育APP',
                image_url: 'https://file2.q5rxr.com/sports-logo-old.png',
                for_ios: 1,
                for_android: 1,
                android_app_url:
                  'https://down.tfstyle.com/20210108MXsport_1.8.4/mx1.8.4%28354%29.apk',
                on_status: 0,
                ios_app_url: 'https://apple.mrb6ns.cn/?str=qsrg9f94hc'
              }
            ],
            msg: 'OK'
          }
          this.data = res.data || []
        })

      // axios
      //   .get(apiUrl + '/api/json/config.json')
      //   .then((res) => {
      //     if (res.data) {
      //       this.customerUrl[1] =
      //         res.data.maintain_cs_url ||
      //         res.data.service_aurl_sec ||
      //         res.data.service_iurl
      //       this.customerUrl[2] =
      //         res.data.service_aurl ||
      //         res.data.service_aurl_sec ||
      //         res.data.service_iurl ||
      //         res.data.maintain_cs_url
      //     }
      //   })
      //   .catch(() => {
      // this.customerUrl[1] =
      //     'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
      //   this.customerUrl[2] =
      //     'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'

      //           })

      this.openUrl = ''
      axios
        .get(apiUrl + '/api/v2/appchannel/1/d999')
        .then((res) => {
          const { data } = res
          this.openUrl = data.data.download_url
        })
        .catch(() => {})

      axios
        .post('/api', {
          url: 'cdb60a1'
        })
        .then(({ data }) => {
          const sign = data.response
          if (sign) {
            axios
              .post('/api', {
                langid: 1,
                url: '901b0a7',
                sign: sign
              })
              .then((res) => {
                try {
                  const result = res.data.data
                  if (result.response) {
                    this.customerUrl[1] = result.response.data.top.dropdownlist
                    this.customerUrl[2] = result.response.data.bottom.dropdownlist
                  }
                } catch (e) {
                  this.customerUrl[1] =
                  'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
                  this.customerUrl[2] =
                  'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
                  throw e
                }
              })
              .catch(() => {
                this.customerUrl[1] =
                  'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
                this.customerUrl[2] =
                  'https://cs.manbetxhbc.com/chatserver/ChatWindow.aspx?siteId=100017501&planId=2'
              })
          }
        })
        .catch(() => {})
    },

    downloadApp (type) {
      if (this.data.length && this.data[type]) {
        window.location.href = this.ios
          ? this.data[type].ios_app_url
          : this.data[type].android_app_url
      } else {
        const data = [
          {
            android_app_url:
              'https://down.tfstyle.com/20210508MX/ManBetX_2.15.20_21520_jiagu_sign.apk',
            ios_app_url: 'https://fir.mrb6ns.cn/downapple?randStr=rci8dmgfs5'
          },
          {
            android_app_url:
              'https://down.tfstyle.com/20210415newsport/newsport20210415.apk',
            ios_app_url: 'https://ck0c5.cn/3yBtOAe.html'
          }
        ]

        window.location.href = this.ios
          ? data[type].ios_app_url
          : data[type].android_app_url
      }
    },
    jumpCustomer (type) {
      window.location.href = this.customerUrl[type]
    },
    open () {
      window.location.href = this.openUrl || 'https://m.mx653.com:2083'
    }
  }
}
</script>

<style lang="scss" scoped>
.c-main {
  .banner {
    img {
      display: block;
      width: 100%;
    }
  }

  .content {
    background: #fff;
    background: #ff5001;
    background: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 345px;
    margin: 0 auto;
    position: relative;
    top: -35px;

    .qrcode {
      margin-top: -0.2rem;
      padding-top: 15px;
      ul {
        overflow: hidden;
        padding: 0 17px;
        text-align: center;

        li {
          float: left;
          width: 45%;
          position: relative;
          height: 160px;
          .rec {
            position: absolute;
            background: #ff4f00;
            border-radius: 8px 0;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            width: 33px;
            height: 21px;
            line-height: 21px;
            left: 0;
          }
          .cont {
            border-radius: 8px;
            text-align: center;
            height: 140px;
            background: rgba(0, 0, 0, 0.06);
          }
          &:first-child {
            margin-right: 10%;
          }
          img {
            width: 80px;
            height: 80px;
            display: block;
            margin: 0 auto;
          }
          .name {
            width: 100%;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            text-align: center;
            line-height: 20px;
          }
          .btn {
            width: 64px;
            height: 24px;
            line-height: 24px;
            font-family: PingFangSC-Medium;
            text-align: center;
            color: #fff;
            background: #ff8a00;
            box-shadow: 0 3px 8px 0 rgba(255, 138, 0, 0.2);
            font-size: 13px;
            letter-spacing: 0;
            position: absolute;
            border-radius: 23px;
            left: 50%;
            margin-left: -32px;
            bottom: 8px;
          }
        }
      }
    }
    .c-list {
      li {
        margin: 0.1rem;
        height: 0.7rem;
        background-color: #f0f0f0;
        border-radius: 7.5px;
        position: relative;
        .ico {
          float: left;
          width: 0.36rem;
          margin: 0.17rem;
          position: absolute;
          left: 0;
          top: 0;
        }
        .cont {
          width: 100%;
          height: 100%;
          float: left;
          font-family: PingFangSC-Medium;
          padding-left: 0.7rem;
          background: url("~@/assets/image/sbtn.png") no-repeat 95% center /
            41px;
          .tit {
            font-size: 0.15rem;
            margin: 0;
            font-weight: bold;
            padding: 0.15rem 0 0.03rem 0;
          }
          p {
            font-size: 0.13rem;
            margin: 0;
          }
        }
      }
    }
    .footer-desc {
      text-align: center;
      font-size: 0;
      padding-top: 25px;
      padding-bottom: 10px;

      .img {
        width: 160px;
      }
      .img-list {
        padding-top: 5px;

        img {
          width: 30px;
          margin-right: 10px;
          &:first-child {
            margin-left: 10px;
          }
        }
      }
      .download {
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 10px;
        width: 130px;
        height: 24px;
        line-height: 24px;
        color: #ff9c37;
        border: 1px solid #ff9c37;
        border-radius: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
